import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "generateButton", "downloadButton"]

  connect() {
    this.generateButtonTarget.disabled = false
    this.downloadButtonTarget.disabled = false
  }

  async generateReport() {
    this.updateStatus("Preparing...")
    this.generateButtonTarget.disabled = true

    const url = this.formTarget.action
    const formData = this.buildFormData()

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      }
    })

    const { job_id } = await response.json()

    this.pollForCompletion(job_id)
  }

  async pollForCompletion(jobId) {
    const interval = 5000
    const maxRetries = 120
    let retries = 0

    while (retries < maxRetries) {
      const response = await fetch(`/admin/jobs/${jobId}`)
      const { status } = await response.json()

      if (status === "ready") {
        this.updateStatus("Baixar relatório")
        this.downloadButtonTarget.disabled = false
        this.generateButtonTarget.disabled = false
        return
      }

      retries++
      this.updateStatus(`Preparando... ${retries}`)
      await new Promise((resolve) => setTimeout(resolve, interval))
    }

    this.updateStatus("File generation time out.")
  }

  updateStatus(message) {
    this.downloadButtonTarget.textContent = message
  }

  buildFormData() {
    const formData = new FormData(this.formTarget)
    formData.append("report[filters][created_at_from]", document.getElementById("datepicker_sb_created_at_from").value)
    formData.append("report[filters][created_at_to]",  document.getElementById("datepicker_sb_created_at_to").value)
    formData.append("report[filters][user_id]", document.getElementById("user_id").value)

    return formData
  }
}